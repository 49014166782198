.site {
	position: relative;
	width: 100%;
	padding-top: $nav-height;
	min-height: 100%;
	overflow: hidden;

	& > .wrap {
		position: relative;
		overflow: hidden;
	}
}

body {
	background-color: $primary;

	&.themed {
		background-color: $alt-primary;
	}
}

.oldie {
	display: block;
	max-width: 300px;
	margin: auto;
	border: solid 1px #020202;
	padding: 15px;
	color: #020202;
	margin-top: 15px;

	a {
		color: #020202;
	}
}

.page {

	position: relative;
	overflow: hidden;
	min-height: 100%;
	padding-top: 0.625rem;

	header {
		display: block;
		overflow: hidden;
		margin-bottom: rem-calc(10);

		.wrapper {
			position: relative;
			z-index: 999;
			overflow: hidden;
			background-color: $aux;

			.themed & {
				background-color: $alt-aux;
			}
		}

		.holder {
			@include clearfix;
			width: 25%;
			position: absolute;
			top: 50px;
			padding-top: rem-calc(20);
			padding-bottom: rem-calc(20);
			z-index: 997;
		}

		h1, h3, h4 {
			padding: 0;
			margin: 0;
			display: block;
			line-height: 1;
			text-transform: uppercase;
			width: 100%;
			color: $primary;

			.themed & {
				color: $alt-secondary;
			}
		}

		h1 {
			display: block;
			width: auto;
			font-size: rem-calc(60);
			text-align: center;
			padding-top: rem-calc(0);
		}

		h3 {
			font-style: italic;
			font-size: rem-calc(14);
			letter-spacing: 1px;
		}

		h4 {
			padding-top: rem-calc(10);
			font-size: rem-calc(14);
			text-align: justify;
			-moz-text-align-last: justify;
			-webkit-text-align-last: justify;
			text-align-last: justify;
			white-space: nowrap;
		}

		h3, h4 {
			padding-left: rem-calc(5);
			padding-right: rem-calc(5);
		}

		h4:after {
			display: inline-block;
			width: 100%;
		}

	}

	.header-cover {
		position: absolute;
		z-index: 998;
		overflow: hidden;
		display: none;
		top: rem-calc(15);
		left: 0;
		width: 100%;
		height: rem-calc(120);
		z-index: 4444;

		div {
			width: 0; 
			height: 0; 
			border-left: 600px solid $aux;
			border-right: 600px solid $aux;
			border-bottom: 120px solid transparent;

			.themed & {
				border-left: 600px solid $alt-aux;
				border-right: 600px solid $alt-aux;
			}

		}
	}

}

@include breakpoint(medium) {

	.page {

		padding-top: 0.9375rem;
	
		header {

			margin-bottom: rem-calc(15);
			
			h1 {
				font-size: rem-calc(75);
			}

			h3 {
				font-size: rem-calc(16);
			}

			h4 {
				font-size: rem-calc(16);
			}

			.holder {
				padding-top: rem-calc(30);
				padding-bottom: rem-calc(30);
			}

		}

	}

}

@include breakpoint(large) {

	.page {

		header {
			
			h1 {
				font-size: rem-calc(125);
			}

			h3 {
				font-size: rem-calc(20);
			}

			h4 {
				font-size: rem-calc(20);
			}

			h3, h4 {
				padding-left: rem-calc(8);
				padding-right: rem-calc(8);
			}

		}

	}

}

@include breakpoint(xlarge) {

	.page {

		padding-top: 0;
	
		header {

			border-bottom: solid 1px $border;

			.themed & {
				border-bottom: solid 1px $alt-border;				
			}
			
			h1 {
				font-size: rem-calc(220);
				letter-spacing: -5px;
			}

			h3 {
				font-size: rem-calc(24);
			}

			h4 {
				font-size: rem-calc(24);
			}

			h3, h4 {
				padding-left: rem-calc(13);
				padding-right: rem-calc(13);
			}

		}

		.header-cover {

			display: block;
			top: 0;

			div {
				border-left-color: $aux;
				border-right-color: $aux;	

				.themed & {
					border-left-color: $alt-aux;
					border-right-color: $alt-aux;	
				}
			}
			
		}

	}

	&.dark {
		header {
			.wrapper {
				background-color: $primary;

				.themed & {
					background-color: $alt-primary;
				}
			}
			h1, h3, h4 {
				color: $secondary;

				.themed & {
					color: $alt-secondary;					
				}
				
			}
		}
	}


}