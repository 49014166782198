.projects {

	.works-list {
		display: block;
		list-style-type: none;
		margin: 0;
		padding: 0;
		@include clearfix;

		li {
			display: block;
			padding: 0;
			padding-right: 0;
			padding-top: 0;
			padding-left: 0;
			position: relative;
			margin-bottom: 0.625rem;

			a {
				display: block;
				background-color: $primary;
				width: 100%;
				cursor: pointer;
				background-position: center;
				background-size: cover;
				background-repeat: no-repeat;
				position: relative;
				border: solid 1px $border;
				text-align: center;
				padding-top: rem-calc(20);
				padding-bottom: rem-calc(10);
				padding-left: rem-calc(10);
				padding-right: rem-calc(10);
				z-index: 4999;
				overflow: hidden;

				.themed & {
					background-color: $alt-primary;
					border: solid 1px $alt-border;
				}

				.backdrop {
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0px;
					left: 0;
					z-index: 5;
				}

				h3 {
					position: relative;
					z-index: 6;
					display: inline-block;
					margin: 0;
					padding: 0;
					text-transform: uppercase;
					font-size: rem-calc(30);
					line-height: 1;
					text-align: center;
					@include clearfix;
					color: $secondary;

					.themed & {
						color: $alt-secondary;
					}

				}

				.althold {
					position: absolute;
					left: 0;
					z-index: 5001;
					width: 100%;
					top: 0;
					height: 0px;
					-webkit-transition: all 400ms ease-out;
					-moz-transition: all 400ms ease-out;
					-ms-transition: all 400ms ease-out;
					-o-transition: all 400ms ease-out;
					transition: all 400ms ease-out;
					overflow: hidden;
					display: block;
					background-color: $primary;
					text-align: center;

					.themed & {
						background-color: $alt-primary;
					}
				}

				h3.alt {
					color: $secondary;

					.themed & {
						color: $alt-secondary;
					}
				}

				.project-name {
					display: block;
					padding-right: 0;
					padding-bottom: rem-calc(10);
				}

				.brand {
					display: block;
					border-top: solid 1px $secondary;	
					margin: auto;

					.themed & {
						border-top: solid 1px $alt-secondary;	
					}
				}

				.logo {
					display: block;
					width: rem-calc(135);
					height: rem-calc(60);
					background-image: url("../img/logos.png");
					background-size: 270px 960px;
					background-position: -135px 0px;
					margin: auto;
				}

				h3.alt {
					.brand {
						border-color: $secondary;

						.themed & {
							border-color: $alt-secondary;
						}
					}
				}
			}

			&.glo {
				a {
					h3 {
						.logo {
							background-position: -270px -360px;

							.themed & {
								background-position: -135px -360px;
							}
						}
					}
				}
			}

			&.dan {
				a {
					h3 {
						.logo {
							background-position: -270px -300px;			

							.themed & {
								background-position: -135px -300px;
							}
						}
					}
				}
			}

			&.rip {
				a {
					h3 {
						.logo {
							background-position: -270px -780px;			

							.themed & {
								background-position: -135px -780px;
							}
						}
					}
				}
			}


			&.ea {
				a {
					h3 {
						.logo {
							background-position: -270px -840px;

							.themed & {
								background-position: -135px -840px;
							}			
						}
					}
				}
			}

			&.jam {
				a {
					h3 {
						.logo {
							background-position: -270px -420px;			

							.themed & {
								background-position: -135px -420px;
							}
						}
					}
				}
			}

			&.boi {
				a {
					h3 {
						.logo {
							background-position: -270px -60px;			

							.themed & {
								background-position: -135px -60px;
							}
						}
					}
				}
			}

			&.uls {
				a {
					h3 {
						.logo {
							background-position: -270px -240px;			

							.themed & {
								background-position: -135px -240px;
							}
						}
					}
				}
			}

			&.mic {
				a {
					h3 {
						.logo {
							background-position: -270px -660px;

							.themed & {
								background-position: -135px -660px;
							}			
						}
					}
				}
			}

			&.vod {
				a {
					h3 {
						.logo {
							background-position: -270px -540px;			

							.themed & {
								background-position: -135px -540px;
							}
						}
					}
				}
			}

			&.cok {
				a {
					h3 {
						.logo {
							background-position: -270px 0px;

							.themed & {
								background-position: -135px 0px;
							}
						}
					}
				}
			}

			&.gui {
				a {
					h3 {
						.logo {
							background-position: -270px -480px;			

							.themed & {
								background-position: -135px -480px;
							}
						}
					}
				}
			}

			&.car {
				a {
					h3 {
						.logo {
							background-position: -270px -600px;

							.themed & {
								background-position: -135px -600px;
							}			
						}
					}
				}
			}

			& {

				.full {
					@include grayscale;

					.backdrop {
						background-color: $aux;
						background-color: rgba(2,2,2,0.8);

						.themed & {
							background-color: $alt-aux;
							background-color: rgba(137,54,45,0.8);
						}
					}
				}

			}

			&.glohealth {
				a.full {
					background-image: url("../img/glo1.jpg");
				}
			}

			&.first {
				a.full {
					background-image: url("../img/first1.jpg");
				}
			}

			&.ginger {
				a.full {
					background-image: url("../img/lime1.jpg");
				}
			}

			&.dragonsden {
				a.full {
					background-image: url("../img/dragon1.jpg");
				}
			}

			&.beatlcr {
				a.full {
					background-image: url("../img/lcr1.jpg");
				}
			}

			&.blackbarrel {
				a.full {
					background-image: url("../img/barrel1.jpg");
				}
			}

			&.ripple {
				a.full {
					background-image: url("../img/ripple2.jpg");
				}
			}

			&.jdiff {
				a.full {
					background-image: url("../img/jdiff1.jpg");
				}
			}

			&.vodafone {
				a.full {
					background-image: url("../img/comedy1.jpg");
				}
			}

			&.windows {
				a.full {
					background-image: url("../img/windows1.jpg");
				}
			}

			&.coke {
				a.full {
					background-image: url("../img/coke2.jpg");
				}
			}

			&.portfolio {
				a.full {
					background-image: url("../img/old2.jpg");
				}
			}


		}

		
	}

}

.project-wrapper {
	background-color: $primary;
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAJElEQVQYV2NkwAT1jGhi9QwMDI3IgmABkCKYIFwAJogiABIEAM2/BIWEpQYtAAAAAElFTkSuQmCC");
	width: 100%;
	min-height: 100%;
	padding: rem-calc(5);
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9999;
	display: none;

	.themed & {
		background-color: $alt-primary;
	}

	&.opened {
		display: block;
	}

	.projects-inner {
		padding: rem-calc(10);
		height: 100%;
		overflow: hidden;
	}

	.project-details {
		margin: auto;
		background-color: $primary;

		.themed & {
			background-color: $alt-primary;
		}	
	}

	.project {
		min-height: 100%;
		
		@include clearfix;

		&:not(:first-child) {
			display: none;
		}

		.project-images {
			width: 100%;
			float: left;
			height: 100%;
			overflow: hidden;
			margin-top: rem-calc(10);
			@include clearfix;

			.main-image {

				img {
					display: block;
					width: 100%;
					border: solid 1px $aux;
					margin-bottom: rem-calc(10);

					.themed & {
						border-color: $alt-aux;
					}
				}

				span {
					display: none;
				}

			}

			.image-list {
				display: none;
				background-color: $aux;
				margin: 0;
				padding: 0;

				.themed & {
					background-color: $alt-aux;
				}
			}

		}

		.project-info {
			width: 100%;
			float: left;
			height: 100%;

			color: $primary;
			background-color: $aux;
			border: solid 1px $border;

			.themed & {
				color: $alt-secondary;
				background-color: $alt-aux;
				border: solid 1px $alt-aux;
			}

			.awards {
				display: block;
				margin: 0;
				padding: 0;
				list-style-type: none;
				margin-top: rem-calc(20);

				li {
					line-height: 1.5;
					font-size: rem-calc(12);
				}

				li:before {
					display: inline-block;
					margin-right: rem-calc(10);
				}
			}

			.stamps {
				display: block;
				list-style-type: none;
				margin: 0;
				padding: 0;
				@include clearfix;
				margin-top: rem-calc(10);

				li {
					display: block;
					border: solid 1px $border;
					width: rem-calc(44);
					height: rem-calc(44);
					float: left;
					margin-right: rem-calc(5);
					font-size: rem-calc(9);
					text-transform: uppercase;
					text-align: center;
					line-height: 1;
					margin-top: rem-calc(5);

					& > span {
						display: table;
						width: 100%;
						height: 100%;
						
						span {
							display: table-cell;
							vertical-align: middle;
							line-height: 1;
						}
					}

					&.fullstack {
						background: $secondary;
						color: $primary;

						.themed & {
							background: $alt-secondary;
							color: $alt-primary;
							border: solid 1px $alt-secondary;
						}
					}

					&.frontend, &.backend {

						background-color: $secondary;
						height: rem-calc(44);
						font-size: rem-calc(8);

						& > span {
							border-bottom: solid rem-calc(21) $primary;	
							color: $primary;
							height: rem-calc(42);
							overflow: hidden;
						}

						.themed & > span {
							border-color: $alt-primary;
							color: $alt-primary;
						}

						.themed & {
							background-color: $alt-secondary;
							border: solid 1px $alt-secondary;
						}
					
					}

					&.backend {

						& > span {
							border-bottom: 0;
							border-top: solid rem-calc(21) $primary;	
							color: $primary;
							height: rem-calc(44);
						}
					
					}


					.themed & {
						border: solid 1px $alt-secondary;
					}
				}
			}

			.project-plate {
				background-color: $primary;
				color: $secondary;
				padding: rem-calc(10);
				display: none;
				position: relative;

				.details-close {
					display: block;
					position: absolute;
					right: 0;
					top: 0;
					width: rem-calc(44);
					height: rem-calc(44);
					background-color: $secondary;
					color: $primary;
					text-align: center;
					font-size: rem-calc(30);
					line-height: rem-calc(44);
					cursor: pointer;

					&:before {
						width: 100%;
					}

					.themed & {
						background-color: $alt-secondary;
						color: $alt-primary;
					}
				}

				.themed & {
					color: $alt-secondary;
					background-color: $alt-primary;
				}

				h2, h3 {
					margin: 0;
					@include oswald;
					text-transform: uppercase;
					font-size: rem-calc(24);
					line-height: 1;
					padding-right: rem-calc(45);
				}

				h3 {
					font-weight: 300;
					font-size: rem-calc(14);
					line-height: 2;
				}
			}

			.project-description {
				padding: rem-calc(10);

				p {
					font-size: rem-calc(12);
					margin: 0;
					line-height: 1.4;

					a {
						color: $primary;
						display: block;
						margin-top: rem-calc(10);

						.themed & {
							color: $alt-secondary;
						}
					}
				}
			}

			&.top-info {
				margin-top: rem-calc(0);

				.project-plate {
					display: block;
				}
				.project-description {
					display: none;
				}
			}
		}


	}
	
}




@include breakpoint(medium) {

	.projects {
	
		.works-list {

			li {
				padding-left: 0.9375rem;
				margin-bottom: 0.9375rem;
				height: rem-calc(235);

				a {
					height: rem-calc(235);
					padding-top: 0;
					padding-bottom: 0;
					
					h3 {
						font-size: rem-calc(36);
					}

					.logo {
						width: rem-calc(135);
						height: rem-calc(60);
					}
				}

			}

			li:nth-of-type(odd) {
				padding-left: 0;
			}


		}
	}

	.project-wrapper {

		.project-details {
			max-width: rem-calc(640);
		}

		.project {

			.project-images {
				width: 100%;
				height: 100%;
			}

			.project-info {
				width: 100%;

				.awards {

					li {
						font-size: rem-calc(14);
					}

				}

				.project-description {
					padding: rem-calc(15);

					p {
						font-size: rem-calc(14);
					}
				}

				.project-plate {
					padding: rem-calc(15);

					h2 {
						font-size: rem-calc(30);
					}
				}
			}


		}
		
	}

}

@include breakpoint(large) {

	.projects {

		.works-list {

			li {

				a {
					@include grayscale;

					.themed & {
						@include no-grayscale;
					}

					h3 {
						color: $primary;

						.themed & {
							color: $alt-secondary;
						}
					}

					.brand {
						border-top: solid 1px $primary;

						.themed & {
							border-top: solid 1px $alt-secondary;
						}
					}

					.backdrop {

						background-color: $aux;
						background-color: rgba(2,2,2,0.8);

						.themed & {
							background-color: $alt-aux;
							background-color: rgba(137,54,45,0.8);
						}
						
					}

				}

				&.glo {
					a {
						h3 {
							.logo {
								background-position: -135px -360px;			
							}
						}
						h3.alt {
							.logo {
								background-position: -270px -360px;			

								.themed & {
									background-position: -135px -360px;			
								}
							}
						}
					}
				}

				&.dan {
					a {
						h3 {
							.logo {
								background-position: -135px -300px;			
							}
						}
						h3.alt {
							.logo {
								background-position: -270px -300px;			

								.themed & {
									background-position: -135px -300px;			
								}
							}
						}
					}
				}

				&.rip {
					a {
						h3 {
							.logo {
								background-position: -135px -780px;			
							}
						}
						h3.alt {
							.logo {
								background-position: -270px -780px;			

								.themed & {
									background-position: -135px -780px;			
								}
							}
						}
					}
				}


				&.ea {
					a {
						h3 {
							.logo {
								background-position: -135px -840px;			
							}
						}
						h3.alt {
							.logo {
								background-position: -270px -840px;			

								.themed & {
									background-position: -135px -840px;			
								}
							}
						}
					}
				}

				&.jam {
					a {
						h3 {
							.logo {
								background-position: -135px -420px;			
							}
						}
						h3.alt {
							.logo {
								background-position: -270px -420px;			

								.themed & {
									background-position: -135px -420px;			
								}
							}
						}
					}
				}

				&.boi {
					a {
						h3 {
							.logo {
								background-position: -135px -60px;			
							}
						}
						h3.alt {
							.logo {
								background-position: -270px -60px;			

								.themed & {
									background-position: -135px -60px;			
								}
							}
						}
					}
				}

				&.uls {
					a {
						h3 {
							.logo {
								background-position: -135px -240px;			
							}
						}
						h3.alt {
							.logo {
								background-position: -270px -240px;			

								.themed & {
									background-position: -135px -240px;			
								}
							}
						}
					}
				}

				&.mic {
					a {
						h3 {
							.logo {
								background-position: -135px -660px;			
							}
						}
						h3.alt {
							.logo {
								background-position: -270px -660px;

								.themed & {
									background-position: -135px -660px;			
								}			
							}
						}
					}
				}

				&.vod {
					a {
						h3 {
							.logo {
								background-position: -135px -540px;			
							}
						}
						h3.alt {
							.logo {
								background-position: -270px -540px;			

								.themed & {
									background-position: -135px -540px;			
								}
							}
						}
					}
				}

				&.cok {
					a {
						h3 {
							.logo {
								background-position: -135px 0px;			
							}
						}
						h3.alt {
							.logo {
								background-position: -270px 0px;	

								.themed & {
									background-position: -135px 0px;			
								}		
							}
						}
					}
				}

				&.gui {
					a {
						h3 {
							.logo {
								background-position: -135px -480px;			
							}
						}
						h3.alt {
							.logo {
								background-position: -270px -480px;			

								.themed & {
									background-position: -135px -480px;			
								}
							}
						}
					}
				}

				&.car {
					a {
						h3 {
							.logo {
								background-position: -135px -600px;			
							}
						}
						h3.alt {
							.logo {
								background-position: -270px -600px;			

								.themed & {
									background-position: -135px -600px;			
								}
							}
						}
					}
				}
				&.glohealth {
					a {
						background-image: url("../img/glo1.jpg");
					}
				}

				&.first {
					a {
						background-image: url("../img/first1.jpg");
					}
				}

				&.ginger {
					a {
						background-image: url("../img/lime1.jpg");
					}
				}

				&.dragonsden {
					a {
						background-image: url("../img/dragon1.jpg");
					}
				}

				&.beatlcr {
					a {
						background-image: url("../img/lcr1.jpg");
					}
				}

				&.blackbarrel {
					a {
						background-image: url("../img/barrel1.jpg");
					}
				}

				&.ripple {
					a {
						background-image: url("../img/ripple2.jpg");
					}
				}

				&.jdiff {
					a {
						background-image: url("../img/jdiff1.jpg");
					}
				}

				&.vodafone {
					a {
						background-image: url("../img/vodafone1.jpg");
					}
				}

				&.windows {
					a {
						background-image: url("../img/windows1.jpg");
					}
				}

				&.coke {
					a {
						background-image: url("../img/coke2.jpg");
					}
				}

				&.portfolio {
					a {
						background-image: url("../img/old2.jpg");
					}
				}

				a:hover {

					.althold {
						height: 100%;
					}

				}

			}

		}
	}

}



@include breakpoint(xlarge) {

	.projects {

		border-bottom: solid rem-calc(200) $aux;

		.themed & {
			border-bottom: solid rem-calc(200) $alt-aux;
		}
	
		.works-list {

			li {

				a {
					min-height: rem-calc(200);
					padding-left: rem-calc(20);
					padding-right: rem-calc(20);
					
					
					h3 {
						font-size: rem-calc(32);
					}

					.project-name {
						margin-top: rem-calc(14);
						padding-right: rem-calc(20);
						float: left;
						text-align: right;
						padding-bottom: 0;
					}

					.brand {
						padding-top: 0;
						padding-left: rem-calc(10);
						float: left;
						border-top: 0;
						border-left: solid 1px $primary;

						.themed & {
							border-left: solid 1px $alt-secondary;
							border-top: 0;
						}
					}

					.logo {
						width: rem-calc(135);
						height: rem-calc(60);
						margin: 0;
					}
				}

				&.jam {
					a {
						.brand {
							padding-left: 0;
						}
					}
				}

				

			}

		}
	}

	.project-wrapper {

		.project-details {
			position: relative;
			max-width: rem-calc(960);
		}
		
		.project {

			height: rem-calc(500);

			.project-images {
				width: rem-calc(640);
				float: left;
				margin-top: 0;

				.main-image {
					width: rem-calc(640);
					height: rem-calc(400);
					position: relative;
					border: solid 1px $aux;
					border-right: 0;
					overflow: hidden;



					.themed & {
						border: solid 1px $alt-aux;
						border-right: 0;
					}

					& > span {
						display: table;
						position: absolute;
						z-index: 3005;
						background-color: $aux;
						width: 100%;
						height: 100%;
						opacity: 1;
						-webkit-transition: all 0ms ease-out;
						-moz-transition: all 0ms ease-out;
						-ms-transition: all 0ms ease-out;
						-o-transition: all 0ms ease-out;
						transition: all 0ms ease-out;
						color: $primary;
						text-align: center;
						font-size: rem-calc(30);

						span {
							display: table-cell;
							vertical-align: middle;
						}

						.themed & {
							background-color: $alt-aux;
							color: $alt-secondary;
						}

						&.loaded {
							-webkit-transition: all 400ms ease-out;
							-moz-transition: all 400ms ease-out;
							-ms-transition: all 400ms ease-out;
							-o-transition: all 400ms ease-out;
							transition: all 400ms ease-out;
							opacity: 0;
						}
					}

					img {
						height: 100%;
						position: absolute;
						margin: 0;
						top: 0;
						left: 0;
						border: 0;
						width: 640px;
						height: 400px;
						z-index: 3000;

						.themed & {
							border: 0;
						}

					}

					img:nth-of-type(1) {
						z-index: 3001;
					}
				}

				.image-list {
					display: block;
					background-color: $secondary;
					margin: 0;
					padding: 0;
					height: rem-calc(100);
					text-align: center;

					.themed & {
						background-color: $alt-aux;
					}

					li {
						display: inline-block;
						margin-left: rem-calc(5);
						margin-right: rem-calc(5);
						margin-top: rem-calc(30);
						border: solid 1px $aux;

						-webkit-transition: all 0ms ease-out;
						-moz-transition: all 0ms ease-out;
						-ms-transition: all 0ms ease-out;
						-o-transition: all 0ms ease-out;
						transition: all 0ms ease-out;

						opacity: 0;

						.themed & {
							border: solid 1px $alt-aux;
						}

						a {

							display: block;
							width: rem-calc(60);
							height: rem-calc(35);
							overflow: hidden;
							border: solid 1px $secondary;
							cursor: pointer;

							.themed & {
								border: solid 1px $alt-aux;
							}
							
						}

						img {
							display: block;
							height: 100%;
							min-width: 100%;
							opacity: 0.6;
						}

						&.active {
							border: solid 1px $primary;

							img {
								opacity: 1;
							}
						}

						.themed &.active {
							border: solid 1px $alt-secondary;
						}

					}

					&.loaded {
						li {

							-webkit-transition: all 400ms ease-out;
							-moz-transition: all 400ms ease-out;
							-ms-transition: all 400ms ease-out;
							-o-transition: all 400ms ease-out;
							transition: all 400ms ease-out;
							opacity: 1;
						
						
						}
					}
				}
			}

			.project-info {
				width: rem-calc(300);
				float: left;
				height: 100%;
				margin-top: 0;
				background-color: $secondary;

				.project-plate {
					display: block;

					h2, h3 {
						margin: 0;
						padding-right: rem-calc(35);
					}

					h2 {
						font-size: rem-calc(30);
					}
				}

			}


		}
		
	}


}


@include breakpoint(xxlarge) {

	.projects {
	
		.works-list {

			li {
				padding-left: 0.9375rem;

				a {
					min-height: rem-calc(200);
					padding: 20px;
					
					h3 {
						font-size: rem-calc(48);
					}

					.project-name {
						margin-top: rem-calc(6);
						padding-right: rem-calc(20);
						float: left;
						text-align: right;
					}

					.brand {
						padding-top: 0;
						padding-left: rem-calc(10);
						float: left;
						border-top: 0;
					}

					.logo {
						width: rem-calc(135);
						height: rem-calc(60);
					}
				}

			}

		}
	}



}
