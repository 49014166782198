.contact {
	
	.contact-info {

		.contact-row {
			@include clearfix;

			& > div {

				padding: 0;
				margin-bottom: rem-calc(10);

				a {
					@include oswald;
					display: block;
					padding: rem-calc(10);
					color: $secondary;
					text-decoration: none;
					line-height: rem-calc(48);
					text-transform: uppercase;
					font-size: rem-calc(20);
					border: solid 1px $border;

					.themed & {
						color: $alt-secondary;
						border: solid 1px $alt-border;
					}

					i {
						display: block;
						font-size: rem-calc(32);
						color: $secondary;
						float: left;
						margin: 0;

						.themed & {
							color: $alt-secondary;
						}
					}

					[class^="icon-"]:before, [class*=" icon-"]:before {
						line-height: 1.5;
						margin-left: rem-calc(5);
						margin-right: rem-calc(15);
					}
				}
			}

			&.social {

				& > div {
					a {
						background-color: $secondary;
						color: $primary;

						.themed & {
							background-color: $alt-aux;
							color: $alt-secondary;
						}

						i {
							color: $primary;

							.themed & {
								color: $alt-secondary;
							}
						}
					}
				}

			}
		}

	}

}

@include breakpoint(medium) {

	.contact {
	
		.contact-info {

			.contact-row {
				
				& > div {

					margin-bottom: rem-calc(15);

				}
			}
		}
	}

}

@include breakpoint(large) {

	.contact {
	
		.contact-info {

			.contact-row {
				
				& > div {

					a {
						@include oswald;
						padding: rem-calc(15);
						line-height: 1;
						text-align: center;
						padding-top: rem-calc(30);
						padding-bottom: rem-calc(30);
						-webkit-transition: all 200ms ease-out;
						-moz-transition: all 200ms ease-out;
						-ms-transition: all 200ms ease-out;
						-o-transition: all 200ms ease-out;
						transition: all 200ms ease-out;
						font-size: rem-calc(24);
						text-transform: uppercase;

						i {
							display: block;
							margin: auto;
							float: none;
							font-size: rem-calc(60);
							margin-bottom: rem-calc(25);
							-webkit-transition: all 200ms ease-out;
							-moz-transition: all 200ms ease-out;
							-ms-transition: all 200ms ease-out;
							-o-transition: all 200ms ease-out;
							transition: all 200ms ease-out;
							text-align: center;
						}

						[class^="icon-"]:before, [class*=" icon-"]:before {
							line-height: 0.7;
							margin: auto;
						}

						&:hover {
							padding-top: rem-calc(20);
							padding-bottom: rem-calc(40);
						}
					}

					
				}

				& > div:nth-of-type(2n+1) {
					padding-left: 0;
					padding-right: rem-calc(8);
				}

				& > div:nth-of-type(2n+2) {
					padding-left: rem-calc(7);
					padding-right: 0;	
				}

				&.social {

					& > div:nth-of-type(3n+1) {
						padding-left: 0;
						padding-right: rem-calc(8);
					}

					& > div:nth-of-type(3n+2) {
						padding-left: rem-calc(7);
						padding-right: rem-calc(8);	
					}

					& > div:nth-of-type(3n+3) {
						padding-left: rem-calc(7);
						padding-right: 0;	
					}

				}
			}

		}

	}

}

@include breakpoint(xlarge) {

	.contact {
	
		.contact-info {

			.contact-row {
				
				& > div {

					a {
						padding: rem-calc(15);
						padding-top: rem-calc(45);
						padding-bottom: rem-calc(45);

						&:hover {
							padding-top: rem-calc(35);
							padding-bottom: rem-calc(55);
						}
					}


					
				}
			}

		}

	}

}