@mixin grayscale {
	filter: url("data:image/svg+xml;utf8,&lt;svg xmlns='http://www.w3.org/2000/svg'&gt;&lt;filter id='grayscale'&gt;&lt;feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
	-webkit-filter: grayscale(100%);
	-moz-filter: grayscale(100%);   
	-ms-filter: grayscale(100%);
	-o-filter: grayscale(100%);
	filter: grayscale(100%);
	filter: gray;	
}

@mixin no-grayscale {
	filter: none;
	-webkit-filter: none;
	-moz-filter: none;   
	-ms-filter: none;
	-o-filter: none;
	
}

@mixin oswald {
	font-family: 'Oswald', sans-serif;
}

@mixin oswald-italic {
	font-weight: 300;
	font-family: 'Oswald', sans-serif;
	font-style: italic;
}

@mixin muli {
	font-family: 'Muli', Arial, Verdana, Helvetica, sans-serif;
}

@mixin acceleration {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);

	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;

	-webkit-perspective: 1000;
	perspective: 1000;

	-webkit-font-smoothing: antialiased;
}