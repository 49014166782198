@-webkit-keyframes preloader {
	0% {
		-webkit-transform: rotateY(0deg);
	}
	50% {
		-webkit-transform: rotateY(180deg);
	}
	100% {
		-webkit-transform: rotateZ(180deg);
	}
}

@keyframes preloader {
	0% {
		-webkit-transform: rotateY(0deg);
		-ms-transform: rotateY(0deg);
		transform: rotateY(0deg);
	}
	50% {
		-webkit-transform: rotateY(180deg);
		-ms-transform: rotateY(180deg);
		transform: rotateY(180deg);
	}
	100% {
		-webkit-transform: rotateZ(180deg);
		-ms-transform: rotateZ(180deg);
		transform: rotateZ(180deg);
	}
}


@-webkit-keyframes drop {
	0% {
		-webkit-transform: translateY(0px);
	}
	100% {
		-webkit-transform: translateY(1200px);
	}
}

@keyframes drop {
	0% {
		-webkit-transform: translateY(0px);
		-ms-transform: translateY(0px);
		transform: translateY(0px);
	}
	100% {
		-webkit-transform: translateY(1200px);
		-ms-transform: translateY(1200px);
		transform: translateY(1200px);
	}
}

@-webkit-keyframes splitline {
	0% {
		max-width: 0;
	}
	50% {
		max-width: 100%;
	}
	100% {
		max-width: 100%;
	}
}

@keyframes splitline {
	0% {
		max-width: 0;
	}
	50% {
		max-width: 100%;
	}
	100% {
		max-width: 100%;
	}
}

@-webkit-keyframes panes {
	0% {
		height: 50%;
	}
	100% {
		height: 0;
	}
}


@keyframes panes {
	0% {
		height: 50%;
	}
	100% {
		height: 0;
	}
}

@-webkit-keyframes close {
	0% {
		top: 0;
	}
	100% {
		top: -200vh;
	}
}

@keyframes close {
	0% {
		top: 0;
	}
	100% {
		top: -200vh;
	}
}

.animate-spin {
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
  display: inline-block;
}
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-o-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-ms-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}




.loader {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;	
	left: 0;
	z-index: 9996;
	text-align: center;
	display: table;
	overflow: hidden;

	.pane-up, .pane-down, .split-line {
		background-color: $secondary;
		width: 100%;
		height: calc(50% + 2px);
		position: absolute;
		top: -1px;
		left: 0;
		z-index: 9997;

		.themed & {
			background-color: $alt-aux;
		}
	}
	.pane-down {
		top: auto;
		bottom: -1px;
		border-top: solid 1px $primary;
		z-index: 9996;

		.themed & {
			border-top: solid 1px $alt-secondary;
		}		
	}

	.split-line {
		box-sizing: border-box;
		z-index: 9998;
		height: 100%;
		max-width: 0px;
		overflow: hidden;
		border-bottom: solid 1px $primary;
		left: 0;

		.themed & {
			border-bottom: solid 1px $alt-secondary;
		}
	}

	.holder {
		display: table-cell;
		width: 100%;
		height: 100%;
		vertical-align: middle;
		position: relative;
		z-index: 9998;

		span {
			display: block;
			width: 14px;
			height: 14px;
			background-color: $primary;
			margin-top: 3px;
			margin-bottom: 3px;
			-webkit-animation: preloader 1.5s infinite;
			animation: preloader 1.5s infinite;

			.themed & {
				background-color: $alt-secondary;
			}

			&:nth-of-type(5n+1) {
				-webkit-animation-delay: $preloader-rotation-delay + ms;
				animation-delay: $preloader-rotation-delay + ms;
			}

			&:nth-of-type(5n+2) {
				-webkit-animation-delay: 2*$preloader-rotation-delay + ms;
				animation-delay: 2*$preloader-rotation-delay + ms;
			}

			&:nth-of-type(5n+3) {
				-webkit-animation-delay: 3*$preloader-rotation-delay + ms;
				animation-delay: 3*$preloader-rotation-delay + ms;
			}

			&:nth-of-type(5n+4) {
				-webkit-animation-delay: 4*$preloader-rotation-delay + ms;
				animation-delay: 4*$preloader-rotation-delay + ms;
			}

			&:nth-of-type(5n+5) {
				-webkit-animation-delay: 5*$preloader-rotation-delay + ms;
				animation-delay: 5*$preloader-rotation-delay + ms;
			}
		}
	}


	.wrap {
		display: inline-block;
		width: 14px;
		position: relative;
		z-index: 9999;
	}

	&.loaded {

		-webkit-animation: close 10ms forwards ease-out;
		-webkit-animation-delay: 2000ms;
		animation: close 10ms forwards ease-out;
		animation-delay: 2000ms;

		.split-line {
			-webkit-animation: splitline 2s forwards cubic-bezier(0.250, 0.250, 0.015, 0.995);
			animation: splitline 2s forwards cubic-bezier(0.250, 0.250, 0.015, 0.995);
			-webkit-animation-delay: 450ms;
			animation-delay: 450ms;
		}

		.pane-up, .pane-down {
			-webkit-animation: panes 400ms forwards ease-out;
			-webkit-animation-delay: 1500ms;
			animation: panes 400ms forwards ease-out;
			animation-delay: 1500ms;
		}

		.holder {

			span {
				-webkit-animation: drop 1s forwards ease-in;
				animation: drop 1s forwards ease-in;

				&:nth-of-type(5n+5) {
					-webkit-animation-delay: $preloader-drop-delay + ms;
					animation-delay: $preloader-drop-delay + ms;
				}

				&:nth-of-type(5n+4) {
					-webkit-animation-delay: 2*$preloader-drop-delay + ms;
					animation-delay: 2*$preloader-drop-delay + ms;
				}

				&:nth-of-type(5n+3) {
					-webkit-animation-delay: 3*$preloader-drop-delay + ms;
					animation-delay: 3*$preloader-drop-delay + ms;
				}

				&:nth-of-type(5n+2) {
					-webkit-animation-delay: 4*$preloader-drop-delay + ms;
					animation-delay: 4*$preloader-drop-delay + ms;
				}

				&:nth-of-type(5n+1) {
					-webkit-animation-delay: 5*$preloader-drop-delay + ms;
					animation-delay: 5*$preloader-drop-delay + ms;
				}
			}
		}
	}

}