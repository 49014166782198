nav {
	width: 100%;
	position: fixed;
	top: 0;
	z-index: 9000;
	border-bottom: solid 1px $primary;
	left: 0;

	.themed & {
		border-bottom: solid 1px $alt-primary;
	}

	&.columns {
		padding: 0;
	}

	.nav-content {
		background-color: $primary;
		height: $nav-height;
		border-bottom: solid 1px $border;
		overflow: hidden;
		padding-left: rem-calc(10);
		padding-right: rem-calc(10);
		text-align: center;
		@include clearfix;

		.themed & {
			background-color: $alt-primary;
			border-bottom: solid 1px $alt-border;
		}

		.logo {
			display: block;
			background-image: url("../img/evident-agenda.png");
			background-repeat: no-repeat;
			background-size: 300px 24px;
			background-position: 0px center;
			width: 150px;
			height: 50px;
			float: left;

			.themed & {
				background-position: -150px center;
			}
		}

		.menu-toggle {
			float: right;
			width: rem-calc(35);
			height: rem-calc(35);
			position: relative;
			-webkit-transform: rotate(0deg);
			-moz-transform: rotate(0deg);
			-o-transform: rotate(0deg);
			transform: rotate(0deg);
			-webkit-transition: .5s ease-in-out;
			-moz-transition: .5s ease-in-out;
			-o-transition: .5s ease-in-out;
			transition: .5s ease-in-out;
			cursor: pointer;
			margin-top: rem-calc(8);
			background-color: $aux;

			.themed & {
				background-color: $alt-aux;
			}

			span {
				display: block;
				position: absolute;
				height: rem-calc(5);
				width: 70%;
				background: $primary;
				opacity: 1;
				left: 0;
				left: 15%;
				-webkit-transform: rotate(0deg);
				-moz-transform: rotate(0deg);
				-o-transform: rotate(0deg);
				transform: rotate(0deg);
				-webkit-transition: .25s ease-in-out;
				-moz-transition: .25s ease-in-out;
				-o-transition: .25s ease-in-out;
				transition: .25s ease-in-out;

				.themed & {
					background: $alt-secondary;
				}

				&:nth-of-type(1) {
				  top: 7px;
				}

				&:nth-of-type(2), &:nth-of-type(3) {
				  top: 15px;
				}

				&:nth-of-type(4) {
				  top: 23px;
				}

			}
		}

		.nav-items {
			display: block;
			list-style-type: none;
			margin: 0;
			padding: 0;
			text-align: left;
			float: none;
			clear: both;
			padding-top: 1px;
			padding-bottom: 1px;
			border-top: solid 1px $border;
			border-bottom: solid 1px $border;
			@include clearfix;
			width: 100%;
			max-width: 100%;

			.themed & {
				border-top: solid 1px $alt-border;
				border-bottom: solid 1px $alt-border;
			}
			

			li {
				@include oswald;
				display: block;
				line-height: rem-calc(30);
				width: 25%;
				float: left;
				font-weight: 700;
				text-align: center;
				text-transform: uppercase;					

				a {
					display: block;
					text-decoration: none;
					width: 100%;
					color: $primary;
					padding-left: rem-calc(10);
					padding-right: rem-calc(10);
					line-height: rem-calc(60);
					font-size: rem-calc(12);
					cursor: pointer;
					background-color: $aux;

					.themed & {
						color: $alt-secondary;
						background-color: $alt-aux;
					}

				}

				&:nth-of-type(1), &:nth-of-type(2), &:nth-of-type(3) {
					padding-right: rem-calc(1);
				}


				&.theme-title {
					@include muli;
					width: 100%;
					font-weight: 300;
					font-size: rem-calc(12);
					line-height: rem-calc(20);
					text-align: left;
					margin: 0;
					text-transform: none;
				}

			}
		}

		.nav-theme {

			display: inline-block;
			float: none;
			list-style-type: none;
			margin: auto;
			padding: 0;
			width: 100%;
			@include clearfix;

			li {

				display: inline-block;
				float: none;
				width: auto;
				text-align: center;
				width: 100%;
				height: rem-calc(35);

				a {
					display: inline-block;
					padding-top: 0;
					width: auto;
					text-align: center;
					cursor: pointer;
					height: rem-calc(35);
					line-height: rem-calc(35);

					i, i:before {
						display: inline-block;
						font-size: rem-calc(12);
						line-height: rem-calc(37);
						float: left;						
					}
					i:before {
						padding-top: rem-calc(12);
						line-height: 1;
					}

					span {
						display: inline-block;
						margin: 0;
						float: left;
						text-decoration: underline;
						font-size: rem-calc(11);
						text-transform: lowercase;
						text-align: left;
						margin-left: rem-calc(5);
					}

				}

			}
			

		}
		
	}

	&.opened {
		
		border-bottom: solid 1px $border;
		box-shadow: 0 20px 60px $border;

		.themed & {
			border-bottom: solid 1px $alt-border;
			box-shadow: 0 20px 60px $alt-border;
		}

		.nav-content {

			height: auto;
			border: 0;

			.menu-toggle {
				span {
					&:nth-of-type(1) {
					  top: 15px;
					  width: 0%;
					  left: 50%;
					}

					&:nth-of-type(2) {
					  -webkit-transform: rotate(45deg);
					  -moz-transform: rotate(45deg);
					  -o-transform: rotate(45deg);
					  transform: rotate(45deg);
					}

					&:nth-of-type(3) {
					  -webkit-transform: rotate(-45deg);
					  -moz-transform: rotate(-45deg);
					  -o-transform: rotate(-45deg);
					  transform: rotate(-45deg);
					}

					&:nth-of-type(4) {
					  top: 15px;
					  width: 0%;
					  left: 50%;
					}
				}
				
			}

			
		}
	}
}

@include breakpoint(medium) {

	nav {

		.nav-content {

			&.row {
				padding-left: rem-calc(15);
				padding-right: rem-calc(15);
			}

			.nav-items {
				li {
					a {
						font-size: rem-calc(18);
					}
				}
			}
		}
	}

}

@include breakpoint(large) {

	nav {

		&.columns {
			padding-left: rem-calc(15);
			padding-right: rem-calc(15);
		}

		@include acceleration;

		.nav-content {

			&.row {
				padding: 0;
			}			


			.menu-toggle {
				display: none;
			}

			.nav-wrap {
				float: right;
				width: auto;
			}

			.nav-items {
				display: inline-block;
				clear: none;
				float: left;
				margin-top: rem-calc(9);
				margin-left: rem-calc(10);
				border: 0;
				padding-top: 0;
				width: auto;

				.themed & {
					border: 0;
				}

				li {
					display: block;
					@include oswald;
					line-height: rem-calc(30);
					text-align: center;
					width: auto;
					float: left;
					margin-bottom: 0;
					font-weight: 300;

					&:nth-of-type(odd) {
						padding-right: 0;
					}

					&:nth-of-type(even) {
						padding-left: 0;
					}

					a {
						border: 0;
						display: inline-block;
						background-color: transparent;
						padding-left: rem-calc(10);
						padding-right: rem-calc(10);
						line-height: rem-calc(32);
						font-size: rem-calc(14);
						cursor: pointer;
						color: $secondary;

						.themed & {
							color: $alt-secondary;
							background-color: transparent;

						}

						&:hover {
							background-color: $aux;
							color: $primary;

							.themed & {
								background-color: $alt-aux;
								color: $alt-secondary;
							}
						}
					}

				}

			}

			.nav-theme {
				
				display: block;
				float: left;
				margin: 0;
				padding: 0;
				max-width: auto;
				clear: none;
				width: auto;
				margin-top: rem-calc(10);				
				padding-left: rem-calc(5);

				li {

					display: block;
					width: rem-calc(30);
					height: rem-calc(30);
					padding: 0;
					height: rem-calc(30);

					a {
						display: inline-block;
						padding: rem-calc(4);
						padding-top: 0;
						width: 100%;
						text-align: center;
						height: rem-calc(30);
						line-height: rem-calc(30);

						i, i:before {
							display: inline-block;
							float: none;
							text-align: center;
							width: 100%;
							line-height: rem-calc(30);
							padding: 0;
						}

						span {
							display: none;
						}

					}


				}
				

			}
			
		}

		&.opened {

			.nav-content {
				height: $nav-height;
			}
		}
	}

}