// colors

$black:		#020202;
$gray:		#a2a2a2;

// animation 

$preloader-rotation-delay: 80;
$preloader-drop-delay: 40;

// nav

$nav-height: rem-calc(50);

////////////////////////////////////////////////////////
// themes
////////////////////////////////////////////////////////

// default

$primary: 					#fff;
$secondary:					#131313;
$aux:						#131313;
$border:					#131313;
$shadow-alpha:				0 1px 1px rgba(255,255,255,0.3), -1px 1px 1px rgba(255,255,255,0.3);

// themed

$alt-primary:	 			#e75b4c;
$alt-secondary:	 			#f0f0f0;
$alt-aux:					#b9493d;
$alt-border:				#89362d;
$alt-shadow-alpha:			0 1px 1px rgba(0,0,0,0.3), -1px 1px 1px rgba(0,0,0,0.3);