.profile {

	h2 {
		display: block;
		text-transform: uppercase;
		text-align: center;
		
		border: solid 1px $border;
		margin: 0;
		padding: 0;
		padding-top: rem-calc(10);
		padding-bottom: rem-calc(10);
		font-size: rem-calc(20);
		line-height: 1;
		border-top: solid rem-calc(5) $border;
		margin-bottom: rem-calc(10);

		.themed & {
			border: solid 1px $alt-border;
			border-top: solid rem-calc(5) $alt-border;
		}
	}

	p {
		font-size: rem-calc(12);
	}

	.skills, .todo, .brands {
		padding: 0;
	}

	.skillset {
		margin: 0;
		padding: 0;
		list-style-type: none;
		display: block;
		@include clearfix;

		li {
			width: 33.3%;
			display: block;
			float: left;
			margin-bottom: rem-calc(10);
			overflow: hidden;

			.wrap {
				display: block;
				background-color: $aux;
				color: $primary;
				text-align: center;					
				border: solid 1px $border;
				font-size: rem-calc(12);
				position: relative;
				overflow: hidden;

				.themed & {
					background-color: $alt-aux;
					color: $alt-secondary;
					border: solid 1px $alt-border;
				}

				.corner {
					width: 0px;
					height: 0px;
					position: absolute;
					top: 0px;
					right: 0px;
					border-width: 0;
					border-style: solid;
					background: $aux;
					-webkit-box-shadow: $shadow-alpha;
					-moz-box-shadow: $shadow-alpha;
					box-shadow: $shadow-alpha;
					border-color: $primary $primary transparent transparent;
					-webkit-transition: border-width 0.2s ease-out;
					-moz-transition: border-width 0.2s ease-out;
					-ms-transition: border-width 0.2s ease-out;
					-o-transition: border-width 0.2s ease-out;
					transition: border-width 0.2s ease-out;

					.themed & {
						background: $alt-aux;
						border-color: $alt-primary $alt-primary transparent transparent;
						-webkit-box-shadow: $alt-shadow-alpha;
						-moz-box-shadow: $alt-shadow-alpha;
						box-shadow: $alt-shadow-alpha;
					}
				}

				span {
					display: table;
					width: 100%;
					height: 100%;
					
					span {
						display: table-cell;
						vertical-align: middle;
						text-align: center;
						padding: rem-calc(4);
						line-height: 1.3;
					}
				}
			}

			
			&.secondary, &.tertiary {
				.wrap {
					background-color: transparent;
					color: $secondary;

					.themed & {
						color: $alt-secondary;
					}

					.corner {
						background: $primary;
						webkit-box-shadow: $alt-shadow-alpha;
						-moz-box-shadow: $alt-shadow-alpha;
						box-shadow: $alt-shadow-alpha;
						border-color: $aux $aux transparent transparent;

						.themed & {
							background: $alt-primary;
							border-color: $alt-aux $alt-aux transparent transparent;
						}
					}
				}
			}

			&.tertiary {
				.wrap {
					border: dashed 1px $border;

					.themed & {
						border: dashed 1px $alt-border;
					}
				}
				
			}

			&:hover {
				.wrap {
					.corner {border-width: 0 20px 20px 0;}
				}
			}


			&:nth-of-type(3n+1) {
				padding-left: 0;
				padding-right: rem-calc(4);

			}

			&:nth-of-type(3n+2) {
				padding-left: rem-calc(4);
				padding-right: rem-calc(4);

			}

			&:nth-of-type(3n+3) {
				padding-right: 0;
				padding-left: rem-calc(4);

			}


		}
	}

	.brands-list {

		text-align: center;
		display: block;
		margin: 0;
		padding: 0;

		li {

			display: inline-block;
			background-image: url("../img/logos.png");
			background-size: 135px 480px;					
			width: 67px;
			height: 30px;
			margin-left: rem-calc(6);
			margin-right: rem-calc(6);
			color: transparent;
			overflow: hidden;

			&.glo {
				background-position: -135px -180px;
			}

			&.boi {
				background-position: -135px -30px;
			}

			&.aib {
				background-position: -135px -60px;
			}

			&.mer {
				background-position: -135px -90px;
			}

			&.cok {
				background-position: -135px 0px;
			}

			&.uls {
				background-position: -135px -120px;
			}

			&.dan {
				background-position: -135px -150px;
			}

			&.jam {
				background-position: -135px -210px;
			}

			&.gui {
				background-position: -135px -240px;
			}

			&.vod {
				background-position: -135px -270px;
			}

			&.car {
				background-position: -135px -300px;
			}

			&.mic {
				background-position: -135px -330px;
			}

			&.bus {
				background-position: -135px -360px;
			}

		}
		
	}

	.skill-legend {
		border-top: solid 1px $border;
		border-bottom: solid 1px $border;
		padding-top: rem-calc(20);
		padding-bottom: rem-calc(20);
		margin-bottom: rem-calc(10);
		text-align: center;

		.themed & {
			border-top: solid 1px $alt-border;
			border-bottom: solid 1px $alt-border;
		}


		h5 {
			margin: 0;
			text-transform: uppercase;
			margin-bottom: rem-calc(10);
		}

		ul {
			display: block;
			list-style-type: none;
			margin: 0;
			padding: 0;

			li {
				display: inline-block;
				line-height: rem-calc(30);
				background-color: $aux;
				color: $primary;
				border: solid 1px $border;
				padding-left: rem-calc(8);
				padding-right: rem-calc(8);
				text-align: center;
				font-size: rem-calc(11);

				.themed & {
					background-color: $alt-aux;
					color: $alt-secondary;
					border: solid 1px $alt-border;
				}

				&.secondary, &.tertiary {
					background-color: transparent;
					border: solid 1px $border;
					color: $secondary;

					.themed & {
						border: solid 1px $alt-border;
						color: $alt-secondary;
					}
				}

				&.tertiary {
					border: dashed 1px $border;

					.themed & {
						border: dashed 1px $alt-border;						
					}	
				}
			}
		}
	}

	.profile-head {

		@include clearfix;

		& > div {

			padding: 0;		

			margin-bottom: rem-calc(10);

			div {
				border: solid 1px $border;
				display: table;
				width: 100%;
				overflow: hidden;

				.themed & {
					border: solid 1px $alt-border;
				}

				h5 {
					
					@include oswald;
					display: table-cell;
					vertical-align: middle;
					width: 100%;
					height: 100%;
					text-align: center;
					line-height: 1;
					font-size: rem-calc(20);
					text-transform: uppercase;
					margin: 0;

					span {
						@include oswald-italic;
						margin-top: rem-calc(5);
						display: block;
						font-size: rem-calc(10);
						line-height: 1.2;
					}

				}
			}
		}


		& > div.profile-image {

			padding-right: rem-calc(5);
			clear: left;

			img {
				display: block;
				width: 100%;

				.themed & {
					opacity: 0.8;
				}
			}
		}

		& > div.profile-name {

			padding-left: rem-calc(5);

		}

		& > div.profile-location {
			padding-left: 0;
			padding-right: rem-calc(5);			
			clear: left;
		}

		& > div.profile-exp {
			padding-left: rem-calc(5);
			padding-right: rem-calc(5);			
		}

		& > div.profile-mileage {
			padding-left: rem-calc(5);
			padding-right: 0;			
		}

		& > div.profile-cv {
			div {
				height: 100%;
				a {
					@include oswald;
					display: table;
					text-align: center;
					background-color: $aux;
					color: $primary;
					text-decoration: none;
					cursor: pointer;
					text-transform: uppercase;
					padding-top: rem-calc(10);
					padding-bottom: rem-calc(10);
					font-size: rem-calc(12);
					height: 100%;
					width: 100%;

					.themed & {
						background-color: $alt-aux;
						color: $alt-secondary;
					}

					span {
						display: table-cell;
						width: 100%;
						height: 100%;
						vertical-align: middle;
					}
				}
			}
		}

	}

}

@include breakpoint(medium) {

	.profile {

		h2 {
			font-size: rem-calc(24);
			border-top: solid rem-calc(6) $border;
			margin-bottom: rem-calc(15);

			.themed & {
				border-top: solid rem-calc(6) $alt-border;
			}
		}

		.skillset {

			li {
				width: 25%;
				margin-bottom: rem-calc(15);
				
				.wrap {
					font-size: rem-calc(12);

					span {
						
						span {
							padding: rem-calc(4);
							line-height: 1.3;
						}
					}
				}

				&:nth-of-type(4n+1) {
					padding-left: 0;
					padding-right: rem-calc(7);

				}

				&:nth-of-type(4n+2), &:nth-of-type(4n+3) {
					padding-left: rem-calc(7);
					padding-right: rem-calc(7);

				}

				&:nth-of-type(4n+4) {
					padding-right: 0;
					padding-left: rem-calc(7);

				}


			}
		}

		.brands-list {

			li {

				background-size: 270px 960px;					
				width: 135px;
				height: 60px;

				&.glo {
					background-position: -270px -360px;
				}

				&.boi {
					background-position: -270px -60px;
				}

				&.aib {
					background-position: -270px -120px;
				}

				&.mer {
					background-position: -270px -180px;
				}

				&.cok {
					background-position: -270px 0px;
				}

				&.uls {
					background-position: -270px -240px;
				}

				&.dan {
					background-position: -270px -300px;
				}

				&.jam {
					background-position: -270px -420px;
				}

				&.gui {
					background-position: -270px -480px;
				}

				&.vod {
					background-position: -270px -540px;
				}

				&.car {
					background-position: -270px -600px;
				}

				&.mic {
					background-position: -270px -660px;
				}

				&.bus {
					background-position: -270px -720px;
				}

			}
			
		}

		.profile-head {

			& > div {

				margin-bottom: rem-calc(15);

				div {

					h5 {

						font-size: rem-calc(32);

						span {
							font-size: rem-calc(12);
						}

					}
				}
			}


			& > div.profile-image {

				padding-right: rem-calc(7);
				clear: left;

				img {
					display: block;
					width: 100%;
				}
			}

			& > div.profile-name {
				padding-left: rem-calc(8);
			}

			& > div.profile-location {
				padding-right: rem-calc(7);			
			}

			& > div.profile-exp {
				padding-left: rem-calc(8);
				padding-right: rem-calc(7);			
			}

			& > div.profile-mileage {
				padding-left: rem-calc(8);
			}

			& > div.profile-cv {
				div {
					a {
						padding-top: rem-calc(15);
						padding-bottom: rem-calc(15);
						font-size: rem-calc(14);
					}
				}
			}

		}

	}
}

@include breakpoint(large) {
	
	.profile {

		h2 {
			padding-top: rem-calc(30);
			padding-bottom: rem-calc(30);
			background-color: $secondary;
			color: $primary;
			border: 0;
			margin-bottom: rem-calc(6);

			.themed & {
				border: 0;
				background-color: $alt-aux;
				color: $alt-secondary;
			}
		}

		.skills, .brands, .todo {
			padding-left: rem-calc(10);
			padding-right: rem-calc(10);

			&:nth-of-type(odd) {
				padding-left: 0;
				clear: left;
			}

			&:nth-of-type(even) {
				padding-right: 0;
			}
		}

		.skillset {

			li {
				width: 33.3%;
				margin-bottom: rem-calc(6);
				
				.wrap {
					font-size: rem-calc(14);

					span {
						
						span {
							padding: rem-calc(4);
							line-height: 1.3;
						}
					}
				}

				&:nth-of-type(3n+1) {
					padding-left: 0;
					padding-right: rem-calc(3);

				}

				&:nth-of-type(3n+2) {
					padding-left: rem-calc(3);
					padding-right: rem-calc(3);

				}

				&:nth-of-type(3n+3) {
					padding-right: 0;
					padding-left: rem-calc(3);

				}

			}
		}

		.skill-legend {
			margin-bottom: rem-calc(15);

			h5 {
				margin-bottom: rem-calc(15);
			}

			ul {

				li {
					padding-left: rem-calc(15);
					padding-right: rem-calc(15);
					font-size: rem-calc(12);
				}
			}
		}

		.profile-head {

			& > div {

				div {

					h5 {

						font-size: rem-calc(18);

						span {
							font-size: rem-calc(12);
							line-height: 1.2;
						}

					}
				}
			}


			& > div.profile-image {
				padding-right: rem-calc(5);
			}

			& > div.profile-name {
				padding-left: rem-calc(5);
				padding-right: rem-calc(5);
			}

			& > div.profile-location {
				padding-left: rem-calc(5);			
				padding-right: rem-calc(5);			
				clear: none;
			}

			& > div.profile-exp {
				padding-left: rem-calc(5);
				padding-right: rem-calc(5);			
			}

			& > div.profile-mileage {
				padding-left: rem-calc(5);
				padding-right: rem-calc(5);			
			}

			& > div.profile-cv {
				padding-left: rem-calc(5);

				div {
					

					a {
						padding-top: 0;
						padding-bottom: 0;
						font-size: rem-calc(14);
						line-height: 1.2;
						-webkit-transition: all 200ms ease-out;
						-moz-transition: all 200ms ease-out;
						-ms-transition: all 200ms ease-out;
						-o-transition: all 200ms ease-out;
						transition: all 200ms ease-out;

						strong {
							
							display: block;
							font-size: rem-calc(48);
						}

						&:hover {
							border: solid rem-calc(10) $primary;
						}

						.themed &:hover {
							border: solid rem-calc(10) $alt-primary;
						}
					}
				}
			}

		}


	}



}

@include breakpoint(xlarge) {

	.profile {

		padding-bottom: 0;

		h2 {
			border: 0;

			.themed & {
				border: 0;
			}
		}

		.skills {
			
			&:nth-of-type(odd), &:nth-of-type(even) {
				padding-left: rem-calc(12);
				padding-right: rem-calc(12);
				clear: none;
			}

			&:nth-of-type(4n+1) {
				padding-left: 0;
			}

			&:nth-of-type(4n+4) {
				padding-right: 0;
			}
		}

		.brands, .todo {
			padding: 0;
		}

		.brands {
			margin-top: rem-calc(15);
			background-color: $aux;
			height: rem-calc(600);

			.themed & {
				background-color: $alt-aux;
			}

		}

		.skillset {

			li {
				width: 50%;
				
				.wrap {
					font-size: rem-calc(14);

					span {
						
						span {
							padding: rem-calc(4);
							line-height: 1.3;
						}
					}
				}

				&:nth-of-type(2n+1) {
					padding-left: 0;
					padding-right: rem-calc(3);

				}

				&:nth-of-type(2n+2) {
					padding-left: rem-calc(3);
					padding-right: 0;

				}

				&:hover {
					.wrap {
						.corner {border-width: 0 30px 30px 0;}
					}
				}

			}
		}

		.todo {
			.skillset {
				li {

					padding-left: rem-calc(3);
					padding-right: rem-calc(3);

					&:nth-of-type(1) {
						padding-left: 0;
						padding-right: rem-calc(3);
					}

					&:hover {
						.wrap {
							.corner {border-width: 0 30px 30px 0;}
						}
					}
				}
			}
		}

		.brands-list {

			li {

				background-size: 270px 960px;					
				width: 135px;
				height: 60px;

				&.glo {
					background-position: -135px -360px;
				}

				&.boi {
					background-position: -135px -60px;
				}

				&.aib {
					background-position: -135px -120px;
				}

				&.mer {
					background-position: -135px -180px;
				}

				&.cok {
					background-position: -135px 0px;
				}

				&.uls {
					background-position: -135px -240px;
				}

				&.dan {
					background-position: -135px -300px;
				}

				&.jam {
					background-position: -135px -420px;
				}

				&.gui {
					background-position: -135px -480px;
				}

				&.vod {
					background-position: -135px -540px;
				}

				&.car {
					background-position: -135px -600px;
				}

				&.mic {
					background-position: -135px -660px;
				}

				&.bus {
					background-position: -135px -720px;
				}

			}
			
		}

		.profile-head {

			& > div {

				div {

					h5 {

						font-size: rem-calc(26);
						vertical-align: middle;
						padding-bottom: 0;

						span {
							font-size: rem-calc(12);
							line-height: 1.2;
						}

					}
				}
			}


			& > div.profile-image {
				padding-right: rem-calc(8);
			}

			& > div.profile-name {
				padding-left: rem-calc(7);
				padding-right: rem-calc(8);
			}

			& > div.profile-location {
				padding-left: rem-calc(7);			
				padding-right: rem-calc(8);			
				clear: none;
			}

			& > div.profile-exp {
				padding-left: rem-calc(7);
				padding-right: rem-calc(8);			
			}

			& > div.profile-mileage {
				padding-left: rem-calc(7);
				padding-right: rem-calc(8);			
			}

			& > div.profile-cv {
				padding-left: rem-calc(7);

				div {
					
					a {
						font-size: rem-calc(16);

						strong {
							font-size: rem-calc(60);
						}
					}
				}
			}

		}


	}

}

@include breakpoint(xxlarge) {

	.profile {

		.profile-head {

			& > div {

				div {

					h5 {

						font-size: rem-calc(30);

						span {
							font-size: rem-calc(16);
						}

					}
				}
			}


			& > div.profile-cv {

				div {
					
					a {

						strong {
							font-size: rem-calc(72);
						}
					}
				}
			}

		}

	}

}