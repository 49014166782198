body {
	font-family: 'Muli', Arial, Verdana, Helvetica, sans-serif;
	font-size: 14px;
	color: $secondary;

	&.themed {
		color: $alt-secondary;	
	}
}

.osb {
	font-family: 'Oswald', sans-serif;
	font-weight: 700;
}

.osl {
	font-family: 'Oswald', sans-serif;
	font-weight: 300;
}

@font-face {
  font-family: 'fontello';
  src: url('../fonts/fontello.eot?95246232');
  src: url('../fonts/fontello.eot?95246232#iefix') format('embedded-opentype'),
       url('../fonts/fontello.woff2?95246232') format('woff2'),
       url('../fonts/fontello.woff?95246232') format('woff'),
       url('../fonts/fontello.ttf?95246232') format('truetype'),
       url('../fonts/fontello.svg?95246232#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

 
[class^="icon-"]:before, [class*=" icon-"]:before {
	font-family: "fontello";
	font-style: normal;
	font-weight: normal;
	speak: none;

	display: block;
	text-decoration: inherit;
	width: 1em;
	text-align: center;
	/* opacity: .8; */

	/* For safety - reset parent styles, that can break glyph codes*/
	font-variant: normal;
	text-transform: none;

	/* Font smoothing. That was taken from TWBS */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

}

.icon-spin1:before { content: '\e830'; } /* '' */
.icon-cancel:before { content: '\e801'; } /* '' */
.icon-mail-alt:before { content: '\f0e0'; } /* '' */
.icon-linkedin:before { content: '\f0e1'; } /* '' */
.icon-color-adjust:before { content: '\f020'; } /* '' */
.icon-github-circled:before { content: '\f09b'; } /* '' */
.icon-stackoverflow:before { content: '\f16c'; } /* '' */
.icon-skype:before { content: '\f17e'; } /* '' */
.icon-award:before { content: '\e800'; } /* '' */
.icon-down-open:before { content: '\e802'; } /* '' */